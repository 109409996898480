import Vue from 'vue'
import App from './App.vue'
import router from './router'
import focusable from 'vue-tv-focusable'
import axios from 'axios'
import VueLazyload from 'vue-lazyload'
axios.defaults.baseURL = '/';
require('./mock');

// 导入字体图标
import './assets/font/iconfont.css'


Vue.use(focusable);
// Vue.use(VueLazyload, {
//   preLoad: 1,
//   error: require('@/assets/image/error.png'),//这个是请求失败后显示的图片
//   loading: require('@/assets/image/error.png'),//这个是加载的loading过渡效果
//   attempt: 2,
//   // try: 2 // 这个是加载图片数量
//   })

Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: require('@/assets/image/error.png'),
  loading: require('@/assets/image/loading.gif'),
  attempt: 1
})

Vue.config.productionTip = false
Vue.prototype.$axios = axios;

// 全局配置，可写可不写，不做配置的情况下就使用vue-tv-focusable默认的配置
const vm = new Vue();

vm.$tv.init({
  KEYS: {
    KEY_LEFT: [37], // ←
    KEY_UP: [38], // ↑
    KEY_RIGHT: [39], // →
    KEY_DOWN: [40], // ↓
    KEY_ENTER: [83, 13], // 83: s键  13：enter键
  },
  // focusClassName:'focus',
  // initDis:100,
  // findFocusType:0,
  // distanceToCenter:true,
  // offsetDistance:100,
  // longPressTime:3000,
});
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
