<template>
  <div id="app">
    <div class="content-app">
      <router-view />
    </div>
  </div>
</template>
<script>
export default {
  name: "App",
  created() { },
};
</script>

<style lang="less">
html {
  font-size: 5.2083vw;
}

body {
  background: #0E1C27;
  // padding: 0.4rem 0.9rem 0.7rem 0.9rem;
}

* {
  margin: 0;
  padding: 0;
  list-style: none;
}

#app {
  display: flex;
  min-height: 100%;
}

.content-app {
  flex: 1;
  // padding-top:20px;
}

.nav-box {
  width: 2.6rem;
  left: 0;
  top: 0;
  bottom: 0;
  padding-top: 20px;
  background-color: #fafafa;
}

.nav-box .nav-item {
  padding: 0.05rem 0.1rem;
  cursor: pointer;
  display: block;
  color: #333;

  &.router-link-exact-active {
    color: #07c160
  }
}

.nav-box .nav-item.div-exact-active {
  background-color: #07c160;
  color: #fff;
}

.nav-box .nav-item.focus,
.nav-box .nav-item.focus,
.nav-box .nav-item.on-focus,
.nav-box .nav-item.focused {
  background-color: #58be8a;
  color: #fff;
}

.font-color {
  color: #D0D0D0;
}

.font-color-wirte {
  color: #fff;
}

input {
  background: none;
  outline: none;
  border: none;
}

input:focus {
  border: none;
}</style>
