import Mock from 'mockjs'


Mock.mock('/api/home/index', 'get', ({body}) => {
    let jinxuan = Mock.mock({
        // 头部推荐
        'recommend|3-3': [
            {
                id: 1,
                title: '中医养生',
                subtitle: '健康养生课程',
                label: '动画',
                img: () => Mock.Random.dataImage('853x366',  Mock.Random.ctitle(3, 20)), // 封面
            },
            {
                id: 1,
                title: '中医养生',
                subtitle: '健康养生课程',
                label: '动画',
                img: () => Mock.Random.dataImage('853x366',  Mock.Random.ctitle(3, 20)), // 封面
            }
            // ...
        ],
    
        // 分组内容
        'list|3-5': [
            {
                group_title: '@ctitle',
                list:[
                    {
                        id: 1,
                        title: '@ctitle',
                        label: '最新',
                        img: () => Mock.Random.dataImage('853x366',  Mock.Random.ctitle(3, 20)),
                        column: 1, // 占 4/1
                    },
                    {
                        id: 1,
                        title: '@ctitle',
                        label: '',
                        img: () => Mock.Random.dataImage('853x366', Mock.Random.ctitle(3, 20)),
                        column: 1, // 占 4/1
                    },
                    {
                        id: 1,
                        title: '@ctitle',
                        label: '最新',
                        img: () => Mock.Random.dataImage('853x366', Mock.Random.ctitle(3, 20)),
                        column: 1, // 占 4/1
                    },
                    {
                        id: 1,
                        title: '@ctitle',
                        label: '',
                        img: () => Mock.Random.dataImage('853x366', Mock.Random.ctitle(3, 20)),
                        column: 1, // 占 4/1
                    },
                    {
                        id: 1,
                        title: '@ctitle',
                        label: '',
                        img: () => Mock.Random.dataImage('853x366', Mock.Random.ctitle(3, 20)),
                        column: 2, // 占 4/2
                    },
                    {
                        id: 1,
                        title: '@ctitle',
                        label: '',
                        img: () => Mock.Random.dataImage('853x366', Mock.Random.ctitle(3, 20)),
                        column: 2, // 占 4/2
                    }
                ]
            }
        ]
    })

    return {
        code: 0,
        message: 'ok',
        data: jinxuan,
    };
})